#hamburger-icon {
    color: $primary-color !important;
}

#menu-appbar ul {
    // background-color: $tertiary-color;
}
#menu-appbar .MuiPaper-elevation1 {
    width: 400px;
}
.navigation {
    max-height: 100px;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    background-color: $primary-text !important;
}
.navigation-logo {
    height: 60px;
}

.MuiMenu-list .MuiButton-root {
    padding: 0 !important;
}

.MuiMenu-list button {
    padding-right: 20px;
    padding-left: 20px;
    &:hover {
        // background-color: transparent;
        background-color: lighten($primary-color, 70);
    }
}
.logout-button {
    .navigation__link {
        cursor: default;
    }
    &:hover {
        background-color: inherit !important;
    }
}
.logout-button__item {
    width: fit-content;
    background-color: $black-color !important;
    color: $primary-text !important;
    // border-radius: 20px !important;
    &:hover {
        background-color: $secondary-color !important;
        color: $primary-text !important;
    }
}
.navigation__link {
    // &:hover {
    // }
    color: $indep_mobile_dark-color;
    width: 100%;
    font-weight: 700;
    padding: 5px;
    &:active {
        color: inherit;
    }
    &-desktop {
        width: fit-content !important;
        &.active {
            color: $indep_mobile-color;
        }
    }
}
.hamburger-menu {
    display: block;
    @media (min-width: 992px) {
        display: none !important;
    }
}
.desktop-menu {
    display: none;
    @media (min-width: 992px) {
        display: flex !important;
    }
}
